
export const taxDefinitions = {
  "2018": {
    brackets: {
      'single': [
        [9525, 10],
        [38700, 12],
        [82500, 22],
        [157500, 24],
        [200000, 32],
        [500000, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'married-joint': [
        [19050, 10],
        [77400, 12],
        [165000, 22],
        [315000, 24],
        [400000, 32],
        [600000, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'widow': [
          [19050, 10],
          [77400, 12],
          [165000, 22],
          [315000, 24],
          [400000, 32],
          [600000, 35],
          [null, 37],
          null,
          null,
          null
      ],
      'married-separate': [
          [9525, 10],
          [38700, 12],
          [82500, 22],
          [157500, 24],
          [200000, 32],
          [300000, 35],
          [null, 37],
          null,
          null,
          null
      ],
      'head-of-household': [
          [13600, 10],
          [51800, 12],
          [82500, 22],
          [157500, 24],
          [200000, 32],
          [500000, 35],
          [null, 37],
          null,
          null,
          null
      ]
    },
    standardDeductions: {
      'single': 12200,
      'married-joint': 24400,
      'widow': 24400,
      'married-separate': 12200,
      'head-of-household': 18350
    },
  },
//  https://www.irs.gov/pub/irs-drop/rp-19-44.pdf
  "2020": {
    brackets: {
      'single': [
        [9875, 10],
        [40125, 12],
        [85525, 22],
        [163300, 24],
        [207350, 32],
        [518400, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'married-joint': [
        [19750, 10],
        [80250, 12],
        [171050, 22],
        [326600, 24],
        [414700, 32],
        [622050, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'widow': [
        [19750, 10],
        [80250, 12],
        [171050, 22],
        [326600, 24],
        [414700, 32],
        [622050, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'married-separate': [
        [9875, 10],
        [40125, 12],
        [85525, 22],
        [163300, 24],
        [207350, 32],
        [311025, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'head-of-household': [
        [14100, 10],
        [53700, 12],
        [85500, 22],
        [163300, 24],
        [207350, 32],
        [518400, 35],
        [null, 37],
        null,
        null,
        null
      ]
    },
    standardDeductions: {
      'single': 12400,
      'married-joint': 24800,
      'widow': 24800,
      'married-separate': 12400,
      'head-of-household': 18350,
    },
  },
  "2019": {
    brackets: {
      'single': [
        [9525, 10],
        [38700, 12],
        [82500, 22],
        [157500, 24],
        [200000, 32],
        [500000, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'married-joint': [
        [19050, 10],
        [77400, 12],
        [165000, 22],
        [315000, 24],
        [400000, 32],
        [600000, 35],
        [null, 37],
        null,
        null,
        null
      ],
      'widow': [
          [19050, 10],
          [77400, 12],
          [165000, 22],
          [315000, 24],
          [400000, 32],
          [600000, 35],
          [null, 37],
          null,
          null,
          null
      ],
      'married-separate': [
          [9525, 10],
          [38700, 12],
          [82500, 22],
          [157500, 24],
          [200000, 32],
          [300000, 35],
          [null, 37],
          null,
          null,
          null
      ],
      'head-of-household': [
          [13600, 10],
          [51800, 12],
          [82500, 22],
          [157500, 24],
          [200000, 32],
          [500000, 35],
          [null, 37],
          null,
          null,
          null
      ]
    },
    standardDeductions: {
      'single': 12200,
      'married-joint': 24400,
      'widow': 24400,
      'married-separate': 12200,
      'head-of-household': 18350
    },
  },
  'M4A': {
    standardDeductions: {
      'single': 12200,
      'married-joint': 24400,
      'widow': 24400,
      'married-separate': 12200,
      'head-of-household': 18350
    },
    brackets: {
      'single': [
        [9525, 10],
        [38700, 12],
        [82500, 22],
        [157500, 24],
        [200000, 32],
        [250000, 35],
        [500000, 40],
        [2000000, 45],
        [10000000, 50],
        [null, 52]
      ],
      'married-joint': [
            [19050, 10],
            [77400, 12],
            [165000, 22],
            [315000, 24],
            [400000, 32],
            [500000, 35],
            [1000000, 40],
            [4000000, 45],
            [20000000, 50],
            [null, 52]
      ],
      'widow': [
            [19050, 10],
            [77400, 12],
            [165000, 22],
            [315000, 24],
            [400000, 32],
            [500000, 35],
            [1000000, 40],
            [4000000, 45],
            [20000000, 50],
            [null, 52]
      ],
      'married-separate': [
            [9525, 10],
            [38700, 12],
            [82500, 22],
            [157500, 24],
            [200000, 32],
            [250000, 35],
            [500000, 40],
            [2000000, 45],
            [10000000, 50],
            [null, 52]
      ],
      'head-of-household': [
            [13600, 10],
            [51800, 12],
            [82500, 22],
            [157500, 24],
            [200000, 32],
            [250000, 35],
            [500000, 40],
            [2000000, 45],
            [10000000, 50],
            [null, 52]
      ]
    },
  }
}

export const healthcareCostAverages = {
  'single': 2639,
  'married-joint': 5277,
  'widow': 2639,
  'married-separate': 2639,
  'head-of-household': 2639
};

export const filingShort = {
  'single': 's',
  'married-joint': 'mj',
  'married-separate': 'ms',
  'head-of-household': 'h',
  'widow': 'w',
};

export const filingLong = {
  'single': 'Single',
  'married-joint': 'Married (Jointly)',
  'married-separate': 'Married (Separately)',
  'head-of-household': 'Head of Household',
  'widow': 'Widow(er)'
};
