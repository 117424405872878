import React, {Component} from "react";
import {filingStatuses, UserForm, Result} from "./UserForm";
import {BracketTable} from './BracketTable';
import {TaxationTable} from './TaxationTable';
import {keyFromValue, ifex, when} from './util';
import {filingShort, taxDefinitions} from './data';
import {Share, ShareHowWePayForIt} from './Share';

const getBracketTaxes = (brackets, income) => {
  let lowerLimit = 0;

  return (
    brackets
    .filter((b) => b !== null)
    .map(([upperLimit, rate]) => {
      let incomeInBracket;
      if (upperLimit === null || (income < upperLimit)) {
        incomeInBracket = income - lowerLimit;
      } else {
        incomeInBracket = upperLimit - lowerLimit;
      }

      lowerLimit = upperLimit;
      if (incomeInBracket > 0) {
        // Tax on income in this bracket
        return incomeInBracket * rate / 100;
      } else {
        return 0;
      }
    })
  );
}

export default class Main extends Component {
  constructor() {
    super();
    this.state = {
      income: undefined,
      filingStatus: filingStatuses.mfj.name,
      healthcareCost: undefined,
      ...this.getStateFromHash(),
      baselineYear: "2019",
    }
  }

  getStateFromHash = () => {
    // Check query hash, and update input model with initial values.
    let update = {}
    if (window.location.hash) {
      const hashVals = window.location.hash.substr(1).split(';');
      const fields = ['income', 'healthcareCost'];
      if(hashVals.length === 3) {
        fields.forEach((field, i) => {
          const v = parseInt(hashVals[i]);
          if(!isNaN(v)) {
            update[fields[i]] = v
          }
        });
        update.filingStatus = keyFromValue(filingShort, hashVals[2], '');
      }
    }
    return update;
  };

  userFormValid = () => {
    const {income, healthcareCost} = this.state;
    return income !== undefined && healthcareCost !== undefined && income !== 0;
  }

  onUpdateUserForm = (update) => {
    this.setState(update);
    const {income: inc = 0, healthcareCost: hc = 0, filingStatus: fil} = {...this.state, ...update};
    if (![inc, hc, fil].includes(0)) {
      window.location.hash = `#${inc};${hc};${filingShort[fil]}`;
    } else {
      window.location.hash = '';
    }
  };

  getData = (brackets, standardDeduction, income) => {
    const data = {
      brackets,
      income,
      standardDeduction,
      taxableIncome: Math.max(income - standardDeduction, 0),
    };

    data.bracketTaxes = data.taxableIncome ? getBracketTaxes(brackets, data.taxableIncome) : [0];
    data.bracketTaxTotal = data.bracketTaxes.reduce((acc, value) => acc + value, 0);
    data.taxOnIncome = data.bracketTaxTotal;
    data.totalTax = data.taxOnIncome;

    if(this.props.dataUpdate) {
      this.props.dataUpdate(data);
    }

    return data;
  }

  dataForYear = (year, filingStatus, income) => {
    const taxDef = taxDefinitions[year];
    const brackets = taxDef.brackets[filingStatus];
    const standardDeduction = taxDef.standardDeductions[filingStatus];

    const data = this.getData(brackets, standardDeduction, income);

    if(year === "M4A") {
      data.m4aTax = 0.04 * data.taxableIncome;
    }

    return data;
  }

  render() {
    const {income, healthcareCost, filingStatus, baselineYear} = this.state;
    const taxTableProps = {
      filingStatus
    };

    const baselineData = {
      ...taxTableProps,
      ...this.dataForYear(baselineYear, filingStatus, income),
    };

    const m4aData = {
      ...taxTableProps,
      ...this.dataForYear("M4A", filingStatus, income),
    };

    const afterTax = (data) => income - data.taxOnIncome;

    const preDisposable = afterTax(baselineData) - healthcareCost;
    const postDisposable = afterTax(m4aData) - m4aData.m4aTax;
    const savings = postDisposable - preDisposable;

    // Display a "share how we pay for it button", if income is above a given
    // threshold...
    const minIncomeHowWePayForIt = 119999;

    return (
      <div>
        <section className="main">
          <div className="container">
            <UserForm
              income={income}
              healthcareCost={healthcareCost}
              filingStatus={filingStatus}
              onUpdate={this.onUpdateUserForm}
            />
            {
              when(this.userFormValid())(() =>
                <Result
                  preDisposable={afterTax(baselineData) - healthcareCost}
                  postDisposable={afterTax(m4aData) - m4aData.m4aTax}
                />
              )
            }
            {
              when(this.userFormValid() && filingStatus === filingStatuses.mfj.name)(() =>
                ifex(savings > 0)(() =>
                  <Share
                    url={window.location.href}
                    savings={savings}
                    income={income}
                  />
                )(() =>
                  when(income > minIncomeHowWePayForIt)(() =>
                    <ShareHowWePayForIt
                      url={window.location.href}
                      income={income}
                      extraTax={m4aData.taxOnIncome + m4aData.m4aTax - baselineData.taxOnIncome}
                    />
                  )
                )
              )
            }
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="current col-sm-6">
                <BracketTable
                  title={`Current (${baselineYear})`}
                  year={baselineYear}
                  income={income}
                  filingStatus={filingStatus}
                  data={baselineData}
                />
              </div>
              <div className="bernie col-sm-6">
                <BracketTable
                  title="Bernie's Proposal"
                  year="M4A"
                  income={income}
                  filingStatus={filingStatus}
                  data={m4aData}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="current col-sm-6">
                <TaxationTable
                  year={baselineYear}
                  title={baselineYear}
                  healthcareCost={healthcareCost}
                  {...baselineData}
                />
              </div>
              <div className="bernie col-sm-6">
                <TaxationTable
                  year="M4A"
                  title="Medicare for All"
                  {...m4aData} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
