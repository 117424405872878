import React, { Component } from "react";
import PropTypes from 'prop-types';
import DollarField from './DollarField';
import {toDollars} from './util';
import {healthcareCostAverages} from './data';

const filingStatus = (name, description, averageHealthCost, shortName) => (
  { name, description, averageHealthCost, shortName }
);

export const filingStatuses = {
  single: filingStatus('single', 'Single', 2639, 's'),
  mfj: filingStatus('married-joint', 'Married', 5277, 'mfj'),
  widow: filingStatus('widow', 'Widow(er)', 2639, 'w'),
  mfs: filingStatus('married-separate', 'Married (Separately)', 2639, 'mfs'),
  hoh: filingStatus('head-of-household', "Head of Household", 2639, 'hoh'),
};

filingStatuses.statuses = [
  filingStatuses.mfj,
  filingStatuses.single,
  filingStatuses.widow,
  filingStatuses.mfs,
  filingStatuses.hoh,
];

export class UserForm extends Component {
  selectAverageHealthCost = () => {
    const avg = healthcareCostAverages[this.props.filingStatus];
    if(avg){
      this.props.onUpdate({healthcareCost: avg});
    }
  };

  render() {
    const {filingStatus, income, healthcareCost, onUpdate} = this.props;
    return (
      <div className="row tax-inputs">
        <div className="col-sm-3">
          <h1 className="text-center">Filing</h1>
          <div className="form-group">
            <select
              tabIndex="0"
              onChange={(e) => onUpdate({filingStatus: e.target.value})}
              value={filingStatus}
              className="form-control"
              id="filing-select"
            >
              {
                filingStatuses.statuses.map((s) =>
                  <option value={s.name} key={s.shortName}>{s.description}</option>
                )
              }
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <h1 className="text-center">Income</h1>
          <div className="form-group">
            <DollarField
              value={income}
              onChange={(v) => onUpdate({income: v})}
              tabIndex="0"
              className="form-control"
              placeholder="e.g. 75000"
            />
          </div>
          <div className="help-text">
            Total annual income: wages and other sources <br />
            (you can use AGI here)
          </div>
        </div>

        <div className="col-sm-5">
          <h1 className="text-center">Healthcare cost</h1>
          <div className="form-group">
            <DollarField
              tabIndex="0"
              className="form-control"
              placeholder="e.g. 5000"
              onChange={(v) => onUpdate({healthcareCost: v})}
              value={healthcareCost}
            />
          </div>
          <div className="help-text dont-know">
            Total annual healthcare spending including co-pays, out-of-pocket expenses, etc.
          </div>
        </div>
      </div>
    );
  }
}


export const Result = (props) => {
  const diff = props.postDisposable - props.preDisposable;
  const diffDisplay = toDollars(diff, 2);

  let diffClass;
  let diffComponent;
  if(diff > 0) {
    diffClass = 'more';
    diffComponent = (
      <p>
        Your annual disposable income will be higher.
        <br/>
        This is how much you will <em>save</em> with a Sanders tax plan.
      </p>
    )
  } else if(diff === 0) {
    diffClass = 'unchanged';
    diffComponent = (
      <p>Your annual disposable income will be <em>unchanged</em><br/> with a Sanders tax plan.</p>
    );
  } else {
    diffClass = 'less';
    diffComponent = (
      <p>
        Your annual disposable income will be <em>lower</em><br/> with a Sanders tax plan.
      </p>
    );
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-lg-offset-3">
        <div id="result" className={diffClass}>
          <span id="diff-amount">{diffDisplay}</span>
          <div id="result-description" className="text-center">
            {diffComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

Result.propTypes = {
  preDisposable: PropTypes.number,
  postDisposable: PropTypes.number,
};

export default UserForm;
