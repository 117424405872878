import React, {Component} from 'react';
import {toDollars, ifex, when} from './util';
import PropTypes from 'prop-types';
import {filingLong} from './data'


export class TaxationTable extends Component {

  render() {
    const {
      income,
      standardDeduction,
      filingStatus,
      taxOnIncome,
      taxableIncome,
      healthcareCost,
      m4aTax
    } = this.props;

    const isM4A = m4aTax !== undefined;

    const disposable = income - taxOnIncome - (healthcareCost || 0) - (m4aTax || 0);

    const healthcarePart = ifex(isM4A)(() =>
      [[<span>M4A 4.0% income-based premium<sup>[1]</sup></span>, "–", toDollars(m4aTax), ""]]
    )(() =>
      [["Annual healthcare spending", "–", toDollars(healthcareCost), ""]]
    );

    const effectiveTaxRate = ifex(taxOnIncome && income)(() =>
      `${((taxOnIncome + (m4aTax || 0)) / taxableIncome * 100.0).toFixed(1)}%`
    )(() => '');

    const data = [
      ["Income (AGI)", "", toDollars(income), ""],
      ["Standard Deduction", "–", toDollars(standardDeduction), ""],
      ["Taxable Income", "=", toDollars(taxableIncome), "emph"],
      ["Income (AGI)", "", toDollars(income), ""],
      ["Tax on taxable income", "–", toDollars(taxOnIncome), ""],
      ...healthcarePart,
      ["Disposable Income", "=", <strong>{toDollars(disposable)}</strong>, "bord-top emph"],
      ["Effective tax rate", "=", effectiveTaxRate, "bord-top"],
    ];

    return (
      <div>
        <table className="default subtotals table table-striped">
          <thead>
            <tr>
              <th colSpan="3">Filing status: <strong>{filingLong[filingStatus]}</strong></th>
            </tr>
          </thead>
          <tbody>
            {
              data.map(([title, op, value, rowClass], i) =>
                <tr key={i} className={rowClass}>
                  <td>{title}</td>
                  <td>{op}</td>
                  <td className="text-right">{value}</td>
                </tr>
              )
            }
          </tbody>
        </table>
        {
          when(isM4A)(() =>
            <ol className="small">
              <li>
                Because of the standard deduction, families of four making less than
                $29,000 a year would not pay this premium.
              </li>
            </ol>
          )
        }
      </div>
    );
  }
}

TaxationTable.propTypes = {
  income: PropTypes.number,
  standardDeduction: PropTypes.number,
  filingStatus: PropTypes.string,
  tax: PropTypes.number,
  taxableIncome: PropTypes.number,
  healthcare: PropTypes.number,
  isM4A: PropTypes.bool,
};

export default TaxationTable;
