

export const toDollars = (amount, n) => {
  if (amount === null || isNaN(amount)) {
    return '';
  }

  var re = '\\d(?=(\\d{3})+' + (n > 0 ? '\\.' : '$') + ')';
  return (amount < 0 ? '- $' : '$')+Math.abs(amount).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

/**
 * Get a key from a value in a dictionary, or return a default value.
 */
export const keyFromValue = (dict, value, orElse) =>
  Object.keys(dict).find((key) => dict[key] === value) || orElse;

/**
 * Use if as an expression. So instead of let x = []; if(p){ x = ... },
 * do ifex(p)(() => ...)(() => [])
 * Nice for inside of jsx brackets where statements are not allowed.
 * @param {*} pred
 */
export const ifex = (pred) => (onTrue) => (onFalse) => {
  if(pred) {
    return onTrue()
  } else if(onFalse){
    return onFalse();
  } else {
    return undefined;
  }
}

export const when = (pred) => (onTrue) => ifex(pred)(onTrue)(undefined);
