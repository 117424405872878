import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toDollars, ifex} from './util';
import _ from 'lodash';

export default class DollarField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  nonZeroDollarsOr = (v, orElse) => ifex(_([undefined, '', 0]).includes(v) || isNaN(v))(() =>
    orElse
  )(() =>
    toDollars(v, 0)
  );

  doOnChange = (strNum) => {
    let newv = undefined;
    if(strNum !== ''){
      newv = parseInt(strNum);
    }
    if(newv === undefined || (!isNaN(newv) && newv >= 0)) {
      this.props.onChange(newv);
    }
  }

  doFocus = (tf) => this.setState({focused: tf});
  blur = () => this.doFocus(false);
  focus = () => this.doFocus(true);

  render() {
    const {value = '', onChange, ...rest} = this.props;
    const {focused} = this.state;

    return (
      <input
        type="text"
        value={focused ? value : this.nonZeroDollarsOr(value, '')}
        onChange={(ev) => this.doOnChange(ev.target.value)}
        onBlur={this.blur}
        onFocus={this.focus}
        {...rest}
      />
    );
  }
}

DollarField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number
};
