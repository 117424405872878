import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {toDollars, ifex} from './util';

const SupportedYears = [
  '2018',
  '2019',
  '2020',
  'M4A'
];



export class BracketTable extends Component {

  render() {
    const {title, minRows = 10, data} = this.props;

    const {brackets, bracketTaxes, taxOnIncome} = data

    const bracketRows = ifex(!_.isEmpty(brackets))(() =>
      _([[0, 0], ...brackets])
      .without(null)
      .zip(_.without(brackets, null))
      .zip(bracketTaxes)
      .dropRight(1)
      .map(([[[lower, ], [upper, rate]], tax]) =>
        <tr key={lower}>
          <td className="text-right bracket-lower">{toDollars(lower)} - </td>
          <td className="text-left bord">{toDollars(upper)}</td>
          <td className="text-right percent-rate">{rate}%</td>
          <td className="text-right amount">{toDollars(tax)}</td>
        </tr>
      )
      .value()
    )(() => []);

    // Need to pad the bottoms of tables,
    // could probably fix css to make this just <tr></tr>
    let emptyRow = (k) => (
      <tr key={k}>
        <td className="text-right bracket-lower">&nbsp;</td>
        <td className="text-left bord"></td>
        <td className="text-right percent-rate"></td>
        <td className="text-right amount"></td>
      </tr>
    );

    return (
      <div>
        <h2>{title}</h2>
        <table className="default brackets table table-striped">
          <thead>
            <tr>
              <th colSpan="2" className="text-center bord income-bracket">
                  <span>Income bracket</span>
              </th>
              <th colSpan="2" className="text-center">Tax</th>
            </tr>
            <tr className="sub">
              <th className="text-right">Lower limit</th>
              <th className="text-left bord">Upper limit</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {bracketRows}
            {_.times(minRows - bracketRows.length, emptyRow)}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2" className="bord"></td>
              <td colSpan="2" className="text-right total">{toDollars(taxOnIncome, 2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

BracketTable.propTypes = {
  title: PropTypes.string,
  year: PropTypes.oneOf(SupportedYears),
};
