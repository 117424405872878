import React, {Component} from 'react';
import {toDollars} from './util';


class Share extends Component {

  render = () => {
    const {
      url,
      income,
      savings
    } = this.props;

    const {
      tweet = `A family making ${toDollars(income)} would save ${toDollars(savings)} per year under Medicare for All. No premiums, no networks, no deductibles. ${url} #bernietax`,
      shareButtonText = 'Share your story'
    } = this.props;


    return (
      <div className="row text-center">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}`}
          className="btn btn-primary btn-lg"
        >
          <i className="fa fa-twitter"></i>&nbsp;{shareButtonText}
        </a>
      </div>
    );
  }
}

const ShareHowWePayForIt = (props) => {
  const {
    income,
    url,
    extraTax
  } = props;

  return (
    <Share
      tweet={`Under #MedicareForAll, a family earning ${toDollars(income)} would pay ${toDollars(extraTax)} more in taxes. That's how we pay for it. No premiums, no networks, no deductibles. ${url} #bernietax`}
      shareButtonText="Share how we pay for it"
      {...props}
    />
  );

}

export default Share

export {
  Share,
  ShareHowWePayForIt,
};
